import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

/**
 * ## A component that creates header component.
 *
 * You can pass input within the oculus-header:
 *
 * - brand - project brand name
 * - brandColor - project brand name color either *primary*(blue) or *gradient* (engie gradient color)
 *
 * You can project contents within the oculus-header.
 *
 * - oculus-header-actions - project contents in the right part of the header title
 * - oculus-header-sub-title - project contents in the below of header. Example is breadcrumbs
 * - custom [no selector] - project contents in the header to fully customize the header
 *
 * @usageNotes
 *
 *
 * #### app.module.ts
 *
 * ```ts
 * import { CommonModule } from '@angular/common';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { FooterModule } from '@oculus/components/footer';
 *
 * import { AppRoutingModule } from './app-routing.module';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *   declarations: [HeaderComponent],
 *   imports: [
 *     CommonModule,
 *     FlexLayoutModule,
 *     BrowserAnimationsModule,
 *     ScrollingModule,
 *   ],
 *   exports: [HeaderComponent],
 * })
 * export class HeaderModule {}
 * ```
 *
 * #### app.component.html
 *
 * ```ts
 * <oculus-header brand="BrandName" brandColor="primary">
 * <div oculus-header-actions>tool bar</div>
 * <div oculus-header-sub-title> Breadcrumbs </div>
 * </oculus-header>
 * <router-outlet></router-outlet>
 *
 * or
 *
 * <oculus-header>
 * <div>tool bar</div>
 * </oculus-header>
 * <router-outlet></router-outlet>
 * ```
 */
@Component({
  selector: 'oculus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() brand = '';
  @Input() brandColor = '';
  isScroll$: Observable<boolean> = of(false);
  public get brandClass(): string {
    return this.brandColor === 'gradient'
      ? 'oculus-header-brand--gradient'
      : 'oculus-header-brand--primary';
  }
  ngOnInit() {
    this.isScroll$ = this.scrollDispatcher.scrolled().pipe(
      map((event) => {
        const component = document.getElementById('oculus-header');
        return component?.clientHeight &&
          (event as CdkScrollable)?.measureScrollOffset('top') >
            component?.clientHeight - 2
          ? true
          : false;
      }),
      tap(() => this.detection.detectChanges()),
    );
  }
  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private detection: ChangeDetectorRef,
  ) {}
}
