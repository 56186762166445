<div
  class="oculus-header-container"
  id="oculus-header"
  [ngClass]="{ 'oculus-header-scroll': (isScroll$ | async) }"
>
  <div class="oculus-header-custom">
    <ng-content></ng-content>
  </div>

  <div class="oculus-header-content" fxLayout="column">
    <div
      class="oculus-header-title"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxFill
    >
      <h1 *ngIf="brand" class="oculus-header-brand" [ngClass]="brandClass">
        {{ brand }}
      </h1>
      <ng-content select="[oculus-header-actions]"></ng-content>
    </div>

    <div class="oculus-header-sub-title">
      <ng-content select="[oculus-header-sub-title]"></ng-content>

    </div>
  </div>
</div>
