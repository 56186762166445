<div class="oculus-footer-container">
  <mat-divider></mat-divider>
  <div
    class="oculus-footer-default"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <ng-content></ng-content>
  </div>
  <ng-content select="[oculus-footer-custom]"></ng-content>
</div>
