import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import {
  DRAWER_COLLAPSING,
  DRAWER_EXPANDING,
  DrawerEvent,
  DrawerNavLink,
} from './drawer.model';

/**
 * ## A component based on Angular Material drawer component and navigation rails.
 *
 * This drawer component has two states: *expanded* and *collapsed*.
 * When expanded, the drawer is loose where navigation links are shown with icon and label.
 * When collapsed, the drawer is compact where navigation links will only show icon.
 *
 * The side-navigation can also be hidden or unhidden for full-content views.
 *
 * You can project contents within the oculus-drawer i.e. router-outlet (see usage notes).
 *
 * There are also project contents in different parts of the drawer:
 *
 * - oculus-drawer-header - project contents in the drawer sidebar top area
 * - oculus-drawer-nav - project contents in the drawer sidebar middle or navigation area
 * - oculus-drawer-footer - project contents in the drawer sidebar bottom area
 * - *default* [no selector] - project contents in the drawer contents area
 *
 * @usageNotes
 *
 *
 * #### app.module.ts
 *
 * ```ts
 * import { CommonModule } from '@angular/common';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { DrawerModule } from '@oculus/components/drawer';
 *
 * import { AppRoutingModule } from './app-routing.module';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     CommonModule,
 *     AppRoutingModule,
 *     DrawerModule,
 *   ],
 *   bootstrap: [AppComponent],
 * })
 * export class AppModule {}
 * ```
 *
 * #### app.component.html
 *
 * ```ts
 * <oculus-drawer>
 *   <div oculus-drawer-header>My Branding</div>
 *   <div oculus-drawer-pre-nav>Before the link</div>
 *   <div oculus-drawer-nav>Sample Link</div>
 *   <div oculus-drawer-post-nav>After the link</div>
 *   <div oculus-drawer-footer>Version: 1.0</div>
 *
 *   <router-outlet></router-outlet>
 * </oculus-drawer>
 * ```
 */
@Component({
  selector: 'oculus-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DrawerComponent {
  @Input() expanded = true;
  @Input() links?: Array<DrawerNavLink>;

  @Input() headerLogo?: string;
  @Input() headerLogoMini?: string;
  @Input() footerLogo?: string;
  @Input() footerLogoMini?: string;

  @Input() tooltipPosition: TooltipPosition = 'right';
  @Input() tooltipDelay = 1000;

  @Input() toggleCollapsedTooltip = 'Expand';
  @Input() toggleExpandedTooltip = 'Collapse';

  @Input() sidenavVisible = true;

  @Output() expandedChange = new EventEmitter<boolean>();

  private toggling: BehaviorSubject<DrawerEvent> = new BehaviorSubject(
    this.expanded ? 'collapsing' : 'expanding',
  );

  collapsing$: Observable<boolean> = this.toggling.pipe(
    map((value) => value === DRAWER_COLLAPSING),
  );

  toggleTooltip$: Observable<string> = this.expandedChange.pipe(
    map((expanded) =>
      expanded ? this.toggleExpandedTooltip : this.toggleCollapsedTooltip,
    ),
  );

  onExpanding() {
    this.toggling.next(DRAWER_EXPANDING);
  }

  onCollapsing() {
    this.toggling.next(DRAWER_COLLAPSING);
  }

  onExpandedChange(expanded: boolean) {
    this.expandedChange.emit(expanded);
  }
}
