export type DrawerNavLink = Readonly<{
  icon: string;
  label: string;
  path: string;

  disabled?: boolean;
  pathExactMatch?: boolean;
}>;

export const DRAWER_EXPANDING = 'expanding';
export const DRAWER_COLLAPSING = 'collapsing';

export type DrawerEvent = typeof DRAWER_EXPANDING | typeof DRAWER_COLLAPSING;
