import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnimationBuilder, AnimationMetadata } from '@angular/animations';
import { Directionality } from '@angular/cdk/bidi';
import {
    AfterViewInit, Directive, ElementRef, forwardRef, Host, HostBinding, Inject, Input, OnDestroy,
    OnInit, Optional, Renderer2, Self
} from '@angular/core';
import {
    MatDrawer, MatDrawerContainer, MatSidenav, MatSidenavContainer
} from '@angular/material/sidenav';

import { sidebarAnimationCloseGroup, sidebarAnimationOpenGroup } from './drawer-rail.animation';
import { DRAWER_RAIL_CONFIG } from './drawer-rail.config';

@Directive({
  selector: 'mat-sidenav[oculusDrawerRail], mat-drawer[oculusDrawerRail]',
})
export class DrawerRailDirective implements OnInit, OnDestroy, AfterViewInit {
  public onDestory: Subject<void> = new Subject();

  private drawer: MatSidenav | MatDrawer;
  private container: MatSidenavContainer | MatDrawerContainer;

  private containerContent: HTMLElement | undefined;

  @Input()
  public openAnimation: AnimationMetadata | AnimationMetadata[] | undefined;

  @Input()
  public closeAnimation: AnimationMetadata | AnimationMetadata[] | undefined;

  @Input()
  public closeWidth: string = DRAWER_RAIL_CONFIG.defaultMinWidth;

  @Input()
  public expandedWidth: string = DRAWER_RAIL_CONFIG.defaultMaxWidth;

  @HostBinding('class.mat-drawer-side') drawerSide = true;

  constructor(
    @Host() @Self() @Optional() sidenav: MatSidenav,
    @Host() @Self() @Optional() drawer: MatDrawer,
    @Inject(forwardRef(() => MatSidenavContainer))
    @Inject(forwardRef(() => MatDrawerContainer))
    @Optional()
    matSideNavContainer: MatSidenavContainer,
    @Optional()
    matDrawerContainer: MatDrawerContainer,
    @Optional() private _dir: Directionality,
    private builder: AnimationBuilder,
    private el: ElementRef<HTMLElement>,
    private renderer2: Renderer2,
  ) {
    this.container = matSideNavContainer || matDrawerContainer;
    this.drawer = sidenav || drawer;
    this.container.hasBackdrop = false;
    this.drawer.autoFocus = false;
  }

  public ngOnInit(): void {
    this.closeAnimation =
      this.closeAnimation ||
      sidebarAnimationCloseGroup(
        DRAWER_RAIL_CONFIG.defaultDuration,
        this.closeWidth,
      );

    this.openAnimation =
      this.openAnimation ||
      sidebarAnimationOpenGroup(
        DRAWER_RAIL_CONFIG.defaultDuration,
        this.expandedWidth,
      );

    this.renderer2.setStyle(
      this.el.nativeElement.querySelector('.mat-drawer-inner-container'),
      'overflow',
      'hidden',
    );

    this.drawer.closedStart.pipe(takeUntil(this.onDestory)).subscribe(() => {
      this.closeMenu();
    });

    this.drawer._closedStream.pipe(takeUntil(this.onDestory)).subscribe(() => {
      this.renderer2.setStyle(this.el.nativeElement, 'visibility', 'visible');
    });

    this.drawer.openedStart.pipe(takeUntil(this.onDestory)).subscribe(() => {
      this.correctContentMargin(this.expandedWidth);
      const factory = this.builder.build(this.openAnimation as AnimationMetadata | AnimationMetadata[])  ;
      const player = factory.create(this.el.nativeElement);
      player.play();
    });
  }

  ngAfterViewInit() {
    if (this.drawer.opened) {
      this.correctContentMargin(this.expandedWidth);
    } else {
      this.renderer2.setStyle(this.el.nativeElement, 'visibility', 'visible');
      this.closeMenu();
    }
  }

  private closeMenu() {
    this.correctContentMargin(this.closeWidth);
    const factory = this.builder.build(this.closeAnimation as AnimationMetadata | AnimationMetadata[]);
    const player = factory.create(this.el.nativeElement);

    player.play();
  }

  private correctContentMargin(width: string) {
    this.containerContent = this.containerContent
      ? this.containerContent
      : (this.el.nativeElement?.parentElement?.querySelector(
          '.mat-drawer-content',
        ) as HTMLElement);

    if (
      (this.drawer.position !== 'end' &&
        this._dir &&
        this._dir.value !== 'rtl') ||
      (this.drawer.position === 'end' && this._dir && this._dir.value === 'rtl')
    ) {
      this.renderer2.setStyle(this.containerContent, 'marginLeft', width);
    } else {
      this.renderer2.setStyle(this.containerContent, 'marginRight', width);
    }
  }

  public ngOnDestroy(): void {
    this.onDestory.next();
    this.onDestory.complete();
  }
}
