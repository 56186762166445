import {
  animate,
  animateChild,
  AnimationGroupMetadata,
  AnimationStyleMetadata,
  group,
  query,
  style,
} from '@angular/animations';

import { DRAWER_RAIL_CONFIG } from './drawer-rail.config';

export function sidebarClose(
  minWidth: string = DRAWER_RAIL_CONFIG.defaultMinWidth,
): AnimationStyleMetadata {
  return style({
    width: minWidth,
    visibility: 'visible',
    transform: 'none',
    overflow: 'hidden',
  });
}

export function sidebarOpen(
  maxWidth: string = DRAWER_RAIL_CONFIG.defaultMaxWidth,
): AnimationStyleMetadata {
  return style({
    width: maxWidth,
    visibility: 'visible',
  });
}

export function sidebarAnimationOpenGroup(
  animationDuration: string = DRAWER_RAIL_CONFIG.defaultDuration,
  maxWidth: string = DRAWER_RAIL_CONFIG.defaultMaxWidth,
): AnimationGroupMetadata {
  return group([
    query('@iconAnimation', animateChild(), { optional: true }),
    query('@labelAnimation', animateChild(), { optional: true }),
    animate(`${animationDuration} ease-in-out`, sidebarOpen(maxWidth)),
  ]);
}

export function sidebarAnimationCloseGroup(
  animationDuration: string = DRAWER_RAIL_CONFIG.defaultDuration,
  minWidth: string = DRAWER_RAIL_CONFIG.defaultMinWidth,
): AnimationGroupMetadata {
  return group([
    query('@iconAnimation', animateChild(), { optional: true }),
    query('@labelAnimation', animateChild(), { optional: true }),
    animate(`${animationDuration} ease-in-out`, sidebarClose(minWidth)),
  ]);
}
