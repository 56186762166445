import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * ## A component that creates footer components under an Angular Material mat divider.
 *
 * You can project contents within the oculus-footer.
 *
 * - oculus-footer-custom - project custom footer contents.
 * - *default* [no selector] - project contents in a single row flex layout.
 *
 * @usageNotes
 *
 *
 * #### app.module.ts
 *
 * ```ts
 * import { CommonModule } from '@angular/common';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { FooterModule } from '@oculus/components/footer';
 *
 * import { AppRoutingModule } from './app-routing.module';
 * import { AppComponent } from './app.component';
 *
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     CommonModule,
 *     AppRoutingModule,
 *     FooterModule,
 *   ],
 *   bootstrap: [AppComponent],
 * })
 * export class AppModule {}
 * ```
 *
 * #### app.component.html
 *
 * ```ts
 * <router-outlet></router-outlet>
 * <oculus-footer></oculus-footer>
 * ```
 */
@Component({
  selector: 'oculus-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {}
