<mat-drawer-container class="oculus-drawer-container" autosize>
  <mat-drawer
    #drawer
    class="oculus-drawer"
    oculusDrawerRail
    [opened]="expanded"
    (openedChange)="onExpandedChange($event)"
    (openedStart)="onExpanding()"
    (closedStart)="onCollapsing()"
    *ngIf="sidenavVisible"
  >
    <div class="oculus-drawer-container" fxLayout="column" fxFill>
      <div
        class="oculus-drawer-header"
        fxLayout="column"
        fxLayoutAlign="space-between"
      >
        <ng-template
          *ngTemplateOutlet="
            logo;
            context: { logo: headerLogo, logoMini: headerLogoMini }
          "
        ></ng-template>

        <ng-content select="[oculus-drawer-header]"></ng-content>

        <div fxFlex="40px"></div>

        <button
          class="oculus-drawer-toggle"
          type="button"
          mat-icon-button
          [matTooltipPosition]="tooltipPosition"
          [matTooltipShowDelay]="tooltipDelay"
          [matTooltip]="(toggleTooltip$ | async)!"
          (click)="drawer.toggle()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>

      <div
        class="oculus-drawer-nav"
        fxFlex
        fxLayout="column"
        fxLayoutAlign="space-between"
      >
        <ng-content select="[oculus-drawer-pre-nav]"></ng-content>

        <mat-nav-list>
          <a
            *ngFor="let link of links"
            mat-list-item
            routerLinkActive="oculus-active-link"
            [routerLinkActiveOptions]="{ exact: !!link.pathExactMatch }"
            [disabled]="link.disabled"
            [matTooltip]="link.label"
            [matTooltipDisabled]="expandedChange | async"
            [matTooltipPosition]="tooltipPosition"
            [matTooltipShowDelay]="tooltipDelay"
            [routerLink]="link.path"
          >
            <mat-icon>{{ link.icon }}</mat-icon>

            <span>{{ link.label }}</span>
          </a>
        </mat-nav-list>

        <ng-content select="[oculus-drawer-nav]"></ng-content>

        <ng-content select="[oculus-drawer-post-nav]"></ng-content>
      </div>

      <div
        class="oculus-drawer-footer"
        fxLayout="column"
        fxLayoutAlign="space-between"
      >
        <ng-template
          *ngTemplateOutlet="
            logo;
            context: { logo: footerLogo, logoMini: footerLogoMini }
          "
        ></ng-template>

        <ng-content select="[oculus-drawer-footer]"></ng-content>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content
    class="oculus-drawer-content"
    [ngClass]="{ 'full-width': !sidenavVisible }"
  >
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #logo let-logo="logo" let-logoMini="logoMini">
  <img
    *ngIf="!!logo"
    alt="Engie logo"
    class="oculus-drawer-logo"
    [src]="logo"
    [style.display]="(collapsing$ | async) ? 'none' : 'block'"
  />

  <img
    *ngIf="!!logoMini && (collapsing$ | async)"
    alt="Engie small logo"
    class="oculus-drawer-logo-mini"
    [src]="logoMini"
  />
</ng-template>
